import { Link } from '@reach/router';
import React from 'react';
import { ROUTES } from 'utils/typings/routes';
import StoreButtons from 'components/StoreButtons';
import { Button } from 'View/Common';
import { redirectToStoreDownload } from 'utils/redirectToStoreDownload';

interface Props {
  renderCta?: boolean;
}

const Footer = (props: Props) => {
  return (
    <footer className="footer">
      {' '}
      {props.renderCta && (
        <div className="home__cta-container margin-top-50">
          <Button
            title="Öffnet den entsprechenden Store zum Herunterladen der App"
            onClick={redirectToStoreDownload}
          >
            App jetzt herunterladen
          </Button>
          <StoreButtons />
        </div>
      )}
      <div className="footer__link-holder">
        <Link className="footer__link" to={ROUTES.CONTACT} title="Kontakt">
          Kontakt
        </Link>
        <Link className="footer__link" to={ROUTES.TERMS} title="AGB">
          AGB
        </Link>
        <Link className="footer__link" to={ROUTES.IMPRINT} title="Impressum">
          Impressum
        </Link>
        <Link className="footer__link" to={ROUTES.PRIVACY} title="Datenschutz">
          Datenschutz
        </Link>
        <Link className="footer__link" to={ROUTES.TERMINATE} title="Verträge hier kündigen">
          Verträge hier kündigen
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
