import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { IconBox } from 'View/Component';
import Header from 'components/Header';
import VoucherForm from 'components/B2B/VoucherForm';
import Footer from 'views/Footer/Footer';

import steps1 from 'img/icon_01.svg';
import steps2 from 'img/icon_02.svg';
import steps3 from 'img/icon_03.svg';
import steps4 from 'img/icon_04.svg';

//////////////////////////////////////////////////////////////////////////////////////

const dataVoucherForm = {
  headlineVoucher: 'Gutschein einlösen',
  sublineVoucher: 'Löse deinen Code ein und werde mit uns zum 7Schläfer.',
  placeholder: 'Gutschein-Code',
  infoResendToken:
    'Du hast deinen Gutschein schon hier eingelöst, aber keinen Freischalt-Link erhalten?'
};

//////////////////////////////////////////////////////////////////////////////////////

const dataHowToContinueIconBox = {
  headline: 'Wie funktioniert das?',
  items: [
    {
      text: 'Nach dem Einlösen deines Codes bekommst Du einen Freischalt-Link an die hier angegebene E-Mail Adresse zugeschickt.',
      icon: steps1
    },
    {
      text: 'Lade die App aus dem Appstore und registriere dich, falls du es nicht bereits hast.',
      icon: steps2
    },
    {
      text: 'Öffne den Freischalt-Link aus der E-Mail auf deinem Mobiltelefon.',
      icon: steps3
    },
    {
      text: 'Alle Inhalte des 7Schläfer Programms werden in der App für dich freigeschaltet.',
      icon: steps4
    }
  ]
};

//////////////////////////////////////////////////////////////////////////////////////

const VoucherLandingpage: React.FC<RouteComponentProps> = (props) => {
  const { path } = props;
  return (
    <>
      <Header noNavigation path={path} />
      <main className="main padding-l-0 padding-r-0">
        <div className="voucher">
          <VoucherForm data={dataVoucherForm} />
          <IconBox
            headline={dataHowToContinueIconBox.headline}
            items={dataHowToContinueIconBox.items}
          />
        </div>
      </main>
      <Footer renderCta />
    </>
  );
};

export default VoucherLandingpage;
