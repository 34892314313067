import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { StaticPageName, useStaticPagesQuery } from 'api/graphql';
import MarkdownRenderer from 'components/MarkdownRenderer';

import Header from 'components/Header';
import Footer from './Footer/Footer';

const Imprint: React.FC<RouteComponentProps> = (props) => {
  const { data, loading, error } = useStaticPagesQuery({
    variables: { name: StaticPageName.Imprint }
  });

  const { path } = props;

  if (error) {
    console.error(error.message);
  }

  return (
    <>
      <Header path={path} />
      <main className="main">
        <div className="wrapper wrapper__min-height padding-top-50-100 flex-column">
          <div className="imprint__markdown-wrapper">
            { data && <MarkdownRenderer data={data.staticPages[0].markdown} /> }
          </div>
          <div className="imprint__image-freddy-laeuft">
            <span className="visuallyhidden">Bild: 7Schläfer Freddy läuft</span>
          </div>
        </div>
      </main>
      <Footer renderCta />
    </>
  );
};

export default Imprint;
