import React from 'react';
import { ROUTES } from 'utils/typings/routes';
import { RouteComponentProps } from '@reach/router';
import Footer from 'views/Footer/Footer';
import error from 'img/error.svg';

const AlreadyPaying: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <main className="main main--no-footer-cta error-page">
        <div className="wrapper flex-column padding-top-150 padding-bottom-100">
          <img src={error} alt="error" className="error-img" />
          <div className="box box__rounded">
            <div className="margin-bottom-33 margin-top-120 wrapper__box flex-column">
              <h2 className="heading  heading-2">Zugang bereits vorhanden</h2>
              <p className="paragraph paragraph__text-center">
                Mit dieser E-Mail-Adresse ist bereits ein aktiver Zugang zu
                7Schläfer verbunden und alle 7Schläfer Inhalte sind für dich
                bereits freigeschaltet. Du hast dazu Fragen? Wende dich gerne an
                uns unter{' '}
                <a
                  href="mailto:support@7schlaefer.app"
                  className="link__markdown"
                  title="Mail an support@7schlaefer.app"
                >
                  support@7schlaefer.app
                </a>
                .
              </p>
            </div>
            <a
              href={ROUTES.PRICING}
              className="link__cta margin-bottom-16"
              data-testid="e2e_already_paying_btn_back"
              title="Gehe zurück zur Preiseübersichts-Seite"
            >
              zurück
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AlreadyPaying;
