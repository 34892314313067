import { navigate, RouteComponentProps } from '@reach/router';
import React, { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Store } from 'utils/store';

import Header from 'components/Header';
import ImgFreddySleep from 'img/freddy-schlaeft.svg';
import Footer from 'views/Footer/Footer';
import { ROUTES } from 'utils/typings/routes';

const Confirmation: React.FC<RouteComponentProps> = (props) => {
  const { path } = props;
  const [store] = useContext(Store);
  const { billingDetails } = store;

  useEffect(() => {
    if (!billingDetails) {
      navigate(ROUTES.PRICING);
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [billingDetails]);

  return (
    <>
      <Header path={path} />
      <main className="main main--no-footer-cta confirmation">
        <div className="wrapper flex-column padding-top-100 margin-bottom-50">
          <h1 className="heading heading-1 margin-bottom-8">
            Kauf abgeschlossen
          </h1>
          <p className="paragraph paragraph__text-center margin-bottom-24">
            Melde dich gleich in der App an.
          </p>
          <div className="box box__rounded">
            <div className="box__elem">
              <div className="margin-bottom-33 wrapper flex-column confirmation__wrapper">
                <h2 className="heading  heading-2 margin-top-16 margin-bottom-8">
                  Geschafft!
                </h2>
                <p className="paragraph paragraph__text-center padding-top-15">
                  Alle Inhalte der App sind nun für dich verfügbar!
                </p>
                <p className="paragraph paragraph__text-center">
                  Dein Kauf wurde mit dem 7Schläfer-Konto <br /> mit der
                  E-Mail-Adresse
                  <br />
                  <span className="text-underline">
                    {billingDetails?.email}
                  </span>
                  &nbsp;verbunden.
                </p>{' '}
                <p className="paragraph paragraph__text-center">
                  Melde dich damit am besten gleich in der App an und leg los.
                </p>
                <p className="paragraph paragraph__text-center padding-bottom-25">
                  Wenn etwas nicht geklappt hat,
                  <br />
                  schreib uns einfach!
                </p>
                {isMobile && (
                  <a
                    href="schlafen://open"
                    className="link__cta align-self-normal"
                    title="Öffnet die App"
                  >
                    APP öffnen
                  </a>
                )}
              </div>
            </div>
          </div>
          <img
            className="freddy-sleep"
            src={ImgFreddySleep}
            alt="7Schläfer Freddy schläft"
          />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Confirmation;
