import { navigate } from '@reach/router';
import React, { useContext } from 'react';
import {
  SinglePaymentOffer,
  StripeCoupon,
  SubscriptionOffer
} from 'api/graphql';
import { ROUTES } from 'utils/typings/routes';
import { isSubscriptionOffer, Store } from 'utils/store';
import localizeAmount from 'utils/localizeAmount';
import { ACTION_TYPES } from 'utils/typings/actionTypes';

interface Props {
  offer: SinglePaymentOffer | SubscriptionOffer;
}

/**
 * @deprecated will be delete in the future
 */
const OfferBox: React.FC<Props> = (props) => {
  const [, dispatch] = useContext(Store);
  const selectOffer = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    offer: SinglePaymentOffer | SubscriptionOffer
  ) => {
    e.preventDefault();
    dispatch({
      type: ACTION_TYPES.SET_SELECTED_OFFER,
      selectedOffer: offer
    });

    navigate(ROUTES.BILLING_DETAILS);
  };

  const couponText = (coupon: StripeCoupon, yearly: Boolean) => {
    switch (coupon.duration) {
      case 'once':
        return 'einmalig, danach normaler Preis';
      case 'repeating':
        if (yearly && coupon.durationInMonths && coupon.durationInMonths < 12) {
          return 'einmalig, danach normaler Preis';
        }
        return (
          'für die ersten ' +
          coupon.durationInMonths +
          ' Monate, danach normaler Preis'
        );
      case 'forever':
      default:
        return '';
    }
  };

  const couponHint = (coupon: StripeCoupon, yearly: Boolean) => {
    const text = couponText(coupon, yearly);
    if (text) {
      return `(${text})`;
    } else {
      return null;
    }
  };

  const { offer } = props;
  return (
    <div
      data-cy-name="offer-box"
      className={`box box__rounded box__payment ${
        offer.highlight ? 'box__payment__highlight' : ''
      }`}
      key={offer.uuid}
    >
      {offer.highlight && <div className="badge">Am beliebtesten</div>}
      <div className="wrapper__box flex-column">
        <h3 className="heading heading-3">{offer.name}</h3>
        {offer.crossedOutPrice ? (
          <>
            <p className="strikethrough-price">
              {offer.crossedOutPrice.localizedPrice}
            </p>

            <h2
              className={`heading heading-2 heading-2__margin-bottom-0 ${
                offer.highlight ? 'heading-2--highlight' : ''
              }`}
            >
              {offer.price.localizedPrice}
            </h2>
          </>
        ) : (
          <h2
            className={`heading heading-2 heading-2__margin-bottom-0 ${
              offer.highlight ? 'heading-2--highlight' : ''
            }`}
          >
            {offer.price.localizedPrice}
          </h2>
        )}
        {isSubscriptionOffer(offer) ? (
          <>
            {offer.interval === 'MONTHLY' && (
              <>
                <p
                  className={`paragraph paragraph__monthly ${
                    offer.coupon && 'margin-bottom-0'
                  }`}
                >
                  monatlich*
                </p>
                {offer.coupon && (
                  <p className="paragraph paragraph__hint paragraph__text-center crossed-out-price">
                    {couponHint(offer.coupon, false)}
                  </p>
                )}
              </>
            )}
            {offer.interval === 'YEARLY' && (
              <>
                <p className="paragraph paragraph__monthly">jährlich*</p>
                <p
                  className={`paragraph paragraph__monthly ${
                    offer.coupon && 'margin-bottom-0'
                  }`}
                >
                  entspricht{' '}
                  {localizeAmount(
                    ((offer.price.cents / 100) * 100) / 100 / 12,
                    true
                  )}{' '}
                  € im Monat
                </p>

                {offer.coupon && (
                  <p className="paragraph paragraph__hint paragraph__text-center">
                    {couponHint(offer.coupon, true)}
                  </p>
                )}
              </>
            )}
          </>
        ) : (
          <p className="paragraph paragraph__monthly">einmalig</p>
        )}
        <button
          data-cy-name="buy-button"
          className={`${
            offer.highlight ? 'pricing__btn--orange' : 'pricing__btn--blue'
          } ${offer.name.toLowerCase()}`}
          type="button"
          onClick={(e) => selectOffer(e, offer)}
          data-testid={`e2e_btn_buy_${
            isSubscriptionOffer(offer) ? offer.interval : 'ONETIME'
          }`}
          title="Dieses Angebot kaufen"
        >
          jetzt kaufen
        </button>
      </div>
    </div>
  );
};

export default OfferBox;
