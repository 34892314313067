import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Header from 'components/Header';
import Footer from 'views/Footer/Footer';
import StoreButtons from 'components/StoreButtons';

const AppLinkNotFound: React.FC<RouteComponentProps> = (props) => {
  const { path } = props;

  return (
    <>
      <Header path={path} />
      <main className="main main--no-footer-cta">
        <div className="wrapper flex-column padding-top-150 padding-bottom-100">
          <h1 className="heading heading-1">Öffne die 7Schläfer App</h1>
          <div className="box box__rounded">
            <div className="box__elem padding-30-50">
              <div className="margin-bottom-33 wrapper__box flex-column">
                <p className="paragraph paragraph__text-center padding-bottom-15">
                  Entschuldige, das war ein kleiner Umweg. Öffne einfach die
                  7Schläfer App auf deinem Handy!
                </p>
                <a href="schlafen://" className="link__cta" title="App öffnen">
                  Hier geht‘s zur App
                </a>
              </div>
              <div className="wrapper flex-column">
                <p className="paragraph paragraph__text-center padding-bottom-15">
                  Der Button funktioniert nur, wenn die App auf diesem Gerät
                  installiert ist.
                  <br />
                  Hier kannst du sie herunterladen.
                </p>
                <StoreButtons />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AppLinkNotFound;
