import React, { FunctionComponent } from 'react';
import { SinglePaymentOffer, SubscriptionOffer } from 'api/graphql';
import OfferBox from './DeprecatedOfferBox';

interface IProps {
  offers: {
    zpp: SubscriptionOffer | null;
    subscriptions: (SinglePaymentOffer | SubscriptionOffer)[];
  };
}
/**
 * @deprecated will be delete in the future
 */
const Offers: FunctionComponent<IProps> = ({ offers }) => {

  return (
    <div className="wrapper__offers">
      {offers.subscriptions
        .slice()
        .sort((a, b) => a.position - b.position)
        .map((offer) => (
          <OfferBox
            key={offer.uuid}
            offer={offer as SinglePaymentOffer | SubscriptionOffer}
          />
        ))}
    </div>
  );

};

export default Offers;
