export const COOPERATION_VOUCHER = {
  AOK_BAYERN: 'AOK_BAYERN',
} as const;

export type TCooperationVoucherConfig = Record<keyof typeof COOPERATION_VOUCHER | 'FALLBACK', {
  staging: string;
  production: string;
}>;

export const COOPERATION_VOUCHER_CONFIG: TCooperationVoucherConfig = {
  [COOPERATION_VOUCHER.AOK_BAYERN]: {
    staging: 'b4e318d1-c76d-4fde-8e1f-4aaa2146864a',
    production: '921c8f8a-416b-490e-b9a3-18b61c10c8d4',
  },
  FALLBACK: {
    staging: 'fallback-staging',
    production: 'fallback-production',
  },
};
