import { RouteComponentProps } from '@reach/router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import Header from 'components/Header';
import public_keys from 'public_keys';
import { environment } from 'utils/environment';
import Footer from 'views/Footer/Footer';
import Subheader from 'views/Payment/SubHeader';
import PaymentMethodForm from './PaymentMethodForm';

// TODO: use process.env once the website can reference env vars at runtime
const STRIPE_PUBLIC_KEY =
  environment === 'staging' || environment === 'development'
    ? public_keys.staging.STRIPE_PUBLIC_KEY
    : public_keys.production.STRIPE_PUBLIC_KEY;

const PaymentMethod: React.FC<RouteComponentProps> = (props) => {
  const { path } = props;

  return (
    <>
      <Header path={path} noNavigation />
      <main className="main main--no-footer-cta">
        <Subheader path={path} />
        <div className="payment-method__wrapper">
          <Elements
            stripe={loadStripe(
              process.env.STRIPE_PUBLIC_KEY || STRIPE_PUBLIC_KEY
            )}
          >
            <PaymentMethodForm />
          </Elements>
        </div>
        <div className="billing__image-luna"></div>
      </main>
      <Footer />
    </>
  );
};

export default PaymentMethod;
