import { RouteComponentProps } from '@reach/router';
import React, { useRef } from 'react';

import { HintBox, IconBox, PartnerLogo } from 'View/Component';
import Header from 'components/Header';
import IntroB2B from 'components/B2B/IntroB2B';
import ExplanationCode from 'components/B2B/ExplanationCode';
import BenefitsProJobs from 'components/B2B/BenefitsProJobs';
import VoucherForm from 'components/B2B/VoucherForm';
import Footer from 'views/Footer/Footer';
import Footer_Branding from 'views/Footer/Footer_Branding';
import { ExternalLink } from 'View/Common';

import steps1 from 'img/icon_01.svg';
import steps2 from 'img/icon_02.svg';
import steps3 from 'img/icon_03.svg';
import steps4 from 'img/icon_04.svg';

//////////////////////////////////////////////////////////////////////////////////////

const dataIntro = {
  headlinePartOne: 'Ausgeschlafen zum Erfolg.',
  headlinePartTwo: 'Mit 7Schläfer zur richtigen Schlafstrategie.',
  imgClassName: 'intro_b2b__hero-image-xing-pro-jobs',
  altText: 'Bild: Luna schreibt auf dem Halbmond',
  subline: 'Für XING ProJobs Mitglieder ist der Zugang für 12 Monate kostenlos.'
};

//////////////////////////////////////////////////////////////////////////////////////

const dataExplanationCode = {
  claim:
    'Für Mitglieder unseres Partners XING ProJobs ist der gesamte Premium Inhalt von 7Schläfer 6 Monate kostenlos.',
  duration: '12 Monate'
};

//////////////////////////////////////////////////////////////////////////////////////

const dataCode = {
  text1: 'Deinen persönlichen Zugangs-Code zu 7Schläfer erhältst du im',
  text2: 'XING ProJobs Vorteilsbereich',
  text3: 'unter „von Profis lernen".'
};

//////////////////////////////////////////////////////////////////////////////////////

const dataVoucherForm = {
  headlineVoucher: 'Zugangs-Code eingeben',
  sublineVoucher:
    'Du hast einen Zugangs-Code von XING ProJobs für 7Schläfer bekommen. Hier kannst du ihn einlösen.',
  placeholder: 'Zugangs-Code',
  infoResendToken:
    'Du hast deinen Zugangs-Code schon hier eingelöst, aber keinen Freischalt-Link erhalten?'
};

//////////////////////////////////////////////////////////////////////////////////////

const dataHowToContinueIconBox = {
  headline: 'Wie funktioniert das?',
  items: [
    {
      text: 'Nach dem Einlösen deines Codes bekommst Du einen Freischalt-Link an die hier angegebene E-Mail Adresse zugeschickt.',
      icon: steps1
    },
    {
      text: 'Lade die App aus dem Appstore und registriere dich, falls du es nicht bereits hast.',
      icon: steps2
    },
    {
      text: 'Öffne den Freischalt-Link aus der E-Mail auf deinem Mobiltelefon.',
      icon: steps3
    },
    {
      text: 'Alle Inhalte des 7Schläfer Programms werden in der App für dich freigeschaltet.',
      icon: steps4
    }
  ]
};

//////////////////////////////////////////////////////////////////////////////////////

const XingProJobsLandingpage: React.FC<RouteComponentProps> = (props) => {
  const xingVoucherRef = useRef<HTMLDivElement>(null);
  const handleRefClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    xingVoucherRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const { path } = props;
  return (
    <>
      <Header noNavigation path={path} />
      <main className="main padding-l-0 padding-r-0">
        <IntroB2B refClick={handleRefClick} data={dataIntro} path={path} />
        <div className="main__wrapper--blue-bg padding-l-0 padding-r-0">
          <BenefitsProJobs />
          <ExplanationCode refClick={handleRefClick} data={dataExplanationCode}>
            {dataCode.text1}
            <>
              &nbsp;
              <ExternalLink
                href="https://www.xing.com/projobs/partners/7schlaefer"
                title="Informationsseite für Kooperation mit Xing-ProJobs öffnen"
              >
                {dataCode.text2}
              </ExternalLink>
              &nbsp;
            </>
            {dataCode.text3}
          </ExplanationCode>
        </div>
        <Footer_Branding isDarkBlue />
        {/* ID hier hat schönere Position zum hinscrollen */}
        <div ref={xingVoucherRef} id="voucher-form"></div>
        <VoucherForm data={dataVoucherForm} />
        <IconBox
          headline={dataHowToContinueIconBox.headline}
          items={dataHowToContinueIconBox.items}
        >
          <HintBox />
        </IconBox>
        <PartnerLogo />
      </main>
      <Footer renderCta />
    </>
  );
};

export default XingProJobsLandingpage;
