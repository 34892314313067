import React, { FC, ReactNode } from 'react';
import Style from './InsuranceLandingPage.style';
import { Menu } from '@missionme/mime_elements';
import '@missionme/mime_elements/dist/public/Css/GlobalSleep.css';
import '@missionme/mime_elements/dist/public/Css/GlobalColors.css';
import '@missionme/mime_elements/dist/public/Css/BootstrapUtils.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from 'views/Footer/Footer';
import { HeaderSection, THeaderSectionProps } from './HeaderSection/HeaderSection';
import { CheckMarkSection, TCheckMarkSectionProps } from './CheckMarkSection/CheckMarkSection';
import { ROUTES } from 'utils/typings/routes';
import { SleepBrandLink } from 'View/Component';

type TProps = {
  children?: ReactNode;
} & {
  HeaderSection?: FC<THeaderSectionProps>;
  CheckMarkSection?: FC<TCheckMarkSectionProps>;
};

export function InsuranceLandingPage({ children }: TProps) {
  return (
    <Style className="insuranceCooperationLandingPage">
      <Menu brandLink={<SleepBrandLink />} />
      {children}
      <Footer renderCta />
    </Style>
  );
}

InsuranceLandingPage.HeaderSection = HeaderSection;
InsuranceLandingPage.CheckMarkSection = CheckMarkSection;
