import React from 'react';
import { ROUTES } from 'utils/typings/routes';
import { RouteComponentProps } from '@reach/router';

import Footer from 'views/Footer/Footer';
import error from 'img/error.svg';

const PaymentError: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <main className="main main--no-footer-cta error-page">
        <div className="wrapper flex-column padding-top-150 padding-bottom-100">
          <img src={error} alt="error" className="error-img" />
          <div className="box box__rounded">
            <div className="margin-bottom-33 margin-top-120 wrapper__box flex-column">
              <h2 className="heading  heading-2">Bitte entschuldige!</h2>
              <p className="paragraph paragraph__text-center">
                Beim Kauf von 7Schläfer ist gerade leider etwas schiefgegangen,
                bitte versuch es doch noch einmal.
              </p>
            </div>
            <a href={ROUTES.PRICING} className="link__cta margin-bottom-16" title="Zurück zur Preisübersichtsseite">
              zurück
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PaymentError;
