import { navigate, RouteComponentProps } from '@reach/router';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import Header from 'components/Header';
import { ROUTES } from 'utils/typings/routes';
import { Store } from 'utils/store';
import Footer from 'views/Footer/Footer';

import Validation from './Validation';
import ErrorFocus from 'utils/Formik_Error_Focus';
import FormikContextHelper from 'utils/Formik_Context_Helper';
import { ACTION_TYPES } from 'utils/typings/actionTypes';
import {
  Cancellation,
  CancellationDate,
  Product,
  useRequestCancellationMutation
} from 'api/graphql';

import DatePicker from 'react-widgets/DatePicker';
import 'react-widgets/scss/styles.scss';

import { logError } from 'utils/logError';

const TerminateForm: React.FC<RouteComponentProps> = (props) => {
  const [, dispatch] = useContext(Store);
  interface IState {
    requestedCancellationAt: String | Date;
  }

  const [state, setState] = useState<IState>({
    requestedCancellationAt: new Date()
  });
  const { path } = props;

  const [RequestCancellation] = useRequestCancellationMutation({
    onCompleted: (data) => {
      if (!data.requestCancellation) {
        logError(new Error('requestCancellation response data missing'));
        return;
      }
      const { uuid, receivedDate, email, confirmationPdfUrl } =
        data.requestCancellation;
      dispatch({
        type: ACTION_TYPES.UPDATE_CANCELLATION,
        terminateDetails: { uuid, receivedDate, email, confirmationPdfUrl }
      });

      navigate(ROUTES.TERMINATE_SUCCESS);
    },
    onError: (error) => {
      logError(error);
      navigate(ROUTES.TERMINATE);
    }
  });

  const handleDateDataChange = (value: String) => {
    setState((prevState) => ({
      ...prevState,
      requestedCancellationAt: value
    }));
  };

  return (
    <>
      <Header path={path} />
      <main className="main terminate">
        <div className="wrapper flex-column padding-top-100">
          <h1 className="heading heading-1 margin-bottom-8">
            Dein Kündigungswunsch
          </h1>
        </div>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            cancellationType: Cancellation.OnTime,
            product: '',
            cancellationDateType: '',
            email: '',
            cancellationReason: ''
          }}
          onSubmit={(values) => {
            let params = {
              cancellationType: values.cancellationType,
              product: values.product,
              requestedCancellationAt: state.requestedCancellationAt,
              email: values.email,
              cancellationReason: values.cancellationReason
            };

            if (values.cancellationDateType) {
              // @ts-ignore
              params['cancellationDateType'] = values.cancellationDateType;
            }

            RequestCancellation({
              // @ts-ignore
              variables: params
            });
          }}
          validate={Validation}
        >
          {({
            errors,
            isSubmitting,
            isValidating,
            handleChange,
            values,
            dirty
          }) => (
            <Form className="terminate__form">
              <div className="terminate__wrapper-lg-w50">
                <p className="paragraph paragraph__text-center margin-bottom-24">
                  Wir bedauern sehr, dass du dein Abo kündigen möchtest. Wenn
                  wir dir irgendwie weiterhelfen können oder es etwas gibt, das
                  dich zum Bleiben überzeugen würde, schreibe uns doch gerne
                  eine E-Mail an <strong>info@7schlaefer.app</strong> mit dem
                  Betreff „Meine Kündigung“ und lass uns wissen, was wir tun
                  können.
                  <br />
                  <br />
                  Bitte beachte: Wenn du dein Abo im Appstore oder Playstore
                  abgeschlossen hast, musst du es dort kündigen. Hier kannst du
                  nur Abos kündigen, die du über unsere Website gekauft hast.
                </p>
                <div className="terminate__input-wrapper">
                  <label className="terminate__label" htmlFor="createdAt">
                    Eingang des Kündigungwunsches:
                  </label>
                  <Field
                    id="createdAt"
                    name="createdAt"
                    className="terminate__input"
                    placeholder="DD.MM.JJJJ - HH:MM"
                    disabled
                  />
                  <p className="terminate__paragraph">
                    Datum und Uhrzeit werden automatisch beim Absenden
                    eingetragen.
                  </p>
                </div>
                <div className="terminate__input-wrapper">
                  <label
                    className="terminate__label"
                    htmlFor="cancellationType"
                  >
                    Art der Kündigung:
                  </label>
                  <Field
                    as="select"
                    className="terminate__input terminate__input__select"
                    name="cancellationType"
                    id="cancellationType"
                    onChange={handleChange}
                  >
                    <option value={Cancellation.OnTime}>
                      Fristgemäße Kündigung
                    </option>
                    <option value={Cancellation.Reasoned}>
                      aus wichtigem Grund
                    </option>
                  </Field>
                </div>
                <div className="terminate__input-wrapper">
                  <label className="terminate__label" htmlFor="product">
                    Zu kündigendes Abonnement:
                  </label>
                  <Field
                    as="select"
                    className={`terminate__input terminate__input__select ${
                      errors.product ? 'terminate__input--error' : ''
                    }`}
                    id="product"
                    name="product"
                    onChange={handleChange}
                  >
                    <option value="">bitte auswählen</option>
                    <option value={Product.Monthly}>Monatsabo</option>
                    <option value={Product.Yearly}>Jahresabo</option>
                  </Field>
                  {errors.product && (
                    <p className="terminate__error-message">{errors.product}</p>
                  )}
                </div>
                <div className="terminate__input-wrapper">
                  <label
                    className="terminate__label"
                    htmlFor="cancellationDateType"
                  >
                    Gewünschter Kündigungszeitpunkt:
                  </label>
                  <Field
                    as="select"
                    id="cancellationDateType"
                    name="cancellationDateType"
                    className={`terminate__input terminate__input__select ${
                      values.cancellationDateType === CancellationDate.Requested
                        ? 'terminate__show-datepicker'
                        : ''
                    }`}
                    onChange={handleChange}
                  >
                    <option value=""> </option>
                    <option value={CancellationDate.EndOfTerm}>
                      Vor nächster Rechnung
                    </option>
                    <option value={CancellationDate.Earliest}>
                      Frühestmöglich
                    </option>
                    <option value={CancellationDate.Requested}>
                      Wunschdatum wählen
                    </option>
                  </Field>
                  {values.cancellationDateType ===
                    CancellationDate.Requested && (
                    <DatePicker
                      id="requestedCancellationAt"
                      name="requested_cancellation_at"
                      className="datepicker"
                      // @ts-ignore
                      onChange={(value) => handleDateDataChange(value)}
                      defaultValue={new Date()}
                      min={new Date()}
                    />
                  )}
                </div>
                <div className="terminate__wrapper-address">
                  <div className="terminate__input-wrapper">
                    <label className="terminate__label" htmlFor="email">
                      E-Mail-Adresse *
                    </label>
                    <Field
                      id="email"
                      name="email"
                      placeholder="E-Mail-Adresse*"
                      className={`terminate__input ${
                        errors.email ? 'terminate__input--error' : ''
                      }`}
                      onChange={handleChange}
                      type="email"
                    />
                    {errors.email && (
                      <p className="terminate__error-message">{errors.email}</p>
                    )}
                    <p className="terminate__paragraph">
                      *Bitte gib hier die E-Mail-Adresse an mit der du deinen
                      Zugang gekauft hast, so dass wir deine Kündigung richtig
                      zuordnen können. An diese E-Mail-Adresse senden wir
                      ebenfalls deine Kündigungsbestätigung.
                    </p>
                  </div>
                  <div className="terminate__input-wrapper">
                    <label
                      className="terminate__label"
                      htmlFor="cancellationReason"
                    >
                      Kündigungsgrund:
                    </label>
                    <Field
                      as="textarea"
                      id="cancellationReason"
                      name="cancellationReason"
                      placeholder="Kündigungsgrund"
                      className={`terminate__input ${
                        errors.cancellationReason
                          ? 'terminate__input--error'
                          : ''
                      }`}
                      onChange={handleChange}
                    />
                    {errors.cancellationReason && (
                      <p className="terminate__error-message">
                        {errors.cancellationReason}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="terminate__wrapper-lg-w50 margin-bottom-50">
                <button className="terminate__btn--orange" type="submit" title="Aktuelles Abo kündigen">
                  jetzt verbindlich kündigen
                </button>
              </div>
              <ErrorFocus
                isSubmitting={isSubmitting}
                isValidating={isValidating}
                errors={errors}
              />
              <FormikContextHelper isDirty={dirty} />
              <div className="imprint__image-freddy-laeuft">
                <span className="visuallyhidden">
                  Bild: 7Schläfer Freddy läuft
                </span>
              </div>
            </Form>
          )}
        </Formik>
      </main>
      <Footer renderCta />
    </>
  );
};

export default TerminateForm;
